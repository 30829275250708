<template>
  <div
    class="window-position"
    :style="{ left: x + 'px', bottom: b + 'px' }"
    v-show="visible"
  >
    <surround-box background='#223446'>
      <div class="content-box" v-html="content"></div>
    </surround-box>
    <!-- <div class="left-corner">
      <svg style="width: 40px; height: 40px" viewbox="0 0 40 40">
        <path
          d="M 20,0 L 0,40 L40,0 z"
          fill="rgba(0,0,0,.5)"
          stroke="none"
        ></path>
      </svg>
    </div> -->
  </div>
</template>
<script>
import * as Cesium from "cesium";
import surroundBox from "@/components/mapComp/surroundBox3";

export default {
  props: {
    viewer: Object,
  },
  components: {
    surroundBox,
  },
  data() {
    return {
      content: "",
      visible: false,
      x: 0,
      b: 0,
      removeCallback: null,
    };
  },
  methods: {
    setContent(htmlStr) {
      this.content = htmlStr;
    },
    getPosition(position) {
      let canvasHeight = this.viewer.scene.canvas.height;
      let windowPosition = Cesium.SceneTransforms.wgs84ToWindowCoordinates(
        this.viewer.scene,
        position._value
      );
      this.x = windowPosition.x;
      this.b = canvasHeight - windowPosition.y;
    },
    show(position) {
      this.getPosition(position);
      if (this.removeCallback) {
        this.removeCallback();
      }
      this.removeCallback = this.viewer.scene.camera.moveEnd.addEventListener(
        () => this.getPosition(position)
      );
      this.visible = true;
    },
    close() {
      if (this.removeCallback) {
        this.removeCallback();
        this.removeCallback = null;
      }
      this.visible = false;
    },
  },
};
</script>
<style lang='less' scoped>
.window-position {
  position: absolute;
  pointer-events: none;
  .content-box {
    padding: 8px;
    background: #223446;
    border-radius: 4px;
  }
  .left-corner {
    text-align: left;
  }
}
</style>